$nav-background: #666;
$panel-background: #666;

.settings {

  .picture {
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    // margin: 24px 0 10px 0
    position: relative;
    width: 150px;
    cursor: pointer;

    .img {
      display: flex;
      width: 150px;
      height: 150px;
      border-radius: 75px;
      justify-content: center;
      align-items: center;
      background: $nav-background;
      color: #f7f7f8;
      font-size: 50px; }

    img {
      width: 150px;
      height: 150px;
      border-radius: 75px; }

    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background: #000000;
      border-radius: 150px; } }

  .picture:hover .overlay {
    opacity: 0.7;

    .text {
      color: #ffffff;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center; } } }
