.details {
  width: 300px;
  height: 100%;
  border-left: 1px solid rgba(218, 220, 224, 0.5);
  .notice {
    font-size: 12px;
    color: #666;
    text-align: center;
    padding: 18px; }
  .rooms {
    height: calc(100% - 55px - 41px - 45px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; } }
.details.expand {
  max-width: 100%;
  min-width: 100%; }

@media screen and (min-width: 1200px) {
  .details {
    max-width: 300px;
    min-width: 300px; } }

.theme.light .details {
  background: #fff; }

.theme.dark .details {
  background: #303841; }

.active {
  color: #da7d02 !important; }
